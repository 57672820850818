import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  container: {
    position: 'relative',
    padding: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '56.25%',
  },
  iframe: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
});

export const imageStyles = makeStyles({
  leftCorner: {
    position: 'absolute',
    top: '5%; left: 5%',
    transform: 'translate(-5%,-5%)',
  },
  centered: {
    width: '25%',
    margin: 'auto',
    position: 'absolute',
    top: '50%; left: 50%',
    transform: 'translate(-50%,-50%)',
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '30%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '20%',
    },
  },
  redbutton: {
    paddingTop: `${theme.spacing(1)}px !important`,
    paddingBottom: `${theme.spacing(1)}px !important`,
    paddingLeft: `${theme.spacing(2)}px !important`,
    paddingRight: `${theme.spacing(2)}px !important`,
    backgroundColor: '#DD2C19 !important',
    color: `${theme.palette.common.white} !important`,
    fontWeight: `${theme.typography.fontWeightBold} !important`,
    fontSize: `${theme.typography.fontSize * 1.15}px !important`,
    '&:hover': {
      backgroundColor: '#AA1B0B !important',
    },
  },
  submitIcon: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  videoMessage: {
    color: '#212121',
    fontWeight: `${theme.typography.fontWeightBold} !important`,
  },
});
