import React, { useState, useEffect } from 'react';
import { string, bool } from 'prop-types';
import loadable from '@loadable/component';

import { Grid } from '@material-ui/core';

import useStyles from './styles';

const LazyYouTube = loadable(() => import('react-youtube'));
const VideoPromoImage = loadable(() => import('components/video/promo-image'));

const VideoCard = ({ videoId = '8gtYahVCOpw' }) => {
  const styles = useStyles();
  const [canPlay, setCanPlay] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(true);
  const options = {
    height: '390',
    width: '640',
    playerVars: { autoplay: 1 },
    origin: 'https://www.total-loyalty.com',
    modestbranding: 1,
  };

  useEffect(() => {
    const evalBrowser = async () => {
      const { detect } = await import('detect-browser');
      const browser = detect();

      if (browser && browser.os === 'iOS') {
        setShowSpinner(false);
        setCanPlay(true);
      }
    };
    evalBrowser();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid spacing={2}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
      <Grid item xs={10} sm={10} md={8} lg={6}>
        <div className={styles.container}>
        {canPlay ? (
          <>
          <span style={loading ? {} : { display: 'none' }}>
            <VideoPromoImage showSpinner={showSpinner}/>
          </span>
          <span style={loading ? { display: 'none' } : {}}>
            <LazyYouTube
              videoId={videoId}
              className={styles.iframe}
              containerClassName={styles.content}
              opts={options}
              onEnd={(e) => {
                e?.target?.cueVideoById?.(videoId);
              }}
              onReady={() => setLoading(false)}/>
          </span>
          </>
        ) : <VideoPromoImage action={() => setCanPlay(true)}/>}
        </div>
      </Grid>
    </Grid>
  );
};

VideoCard.propTypes = {
  videoId: string,
  waitToLoad: bool,
};

export default VideoCard;
